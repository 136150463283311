import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { ReactComponent as AllIcon } from '@assets/parts/cuts/all.svg';
import { ReactComponent as FrontCutIcon } from '@assets/parts/cuts/front-cut.svg';
import { ReactComponent as MidCutIcon } from '@assets/parts/cuts/mid-cut.svg';
import { ReactComponent as OtherIcon } from '@assets/parts/cuts/other.svg';
import { ReactComponent as RearCutIcon } from '@assets/parts/cuts/rear-cut.svg';
import { ReactComponent as UnderbodyIcon } from '@assets/parts/cuts/underbody.svg';
import { sum, uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import { CategoryTreeLeaf, CategoryTreeNode, PartsFormData } from '../../types';
import { categoryLeaves } from '../../utils';

type PartsCutProps = InheritableElementProps<
	'button',
	{
		cut: CategoryTreeNode;
		selected?: CategoryTreeNode | null;
		selection: PartsFormData;
		actions: {
			cut: {
				set: (cut: CategoryTreeNode) => void;
			};
		};
	}
>;

export const PartsCut = ({
	className,
	cut,
	selected,
	selection,
	actions,
	...rest
}: PartsCutProps) => {
	const icons: Record<
		string,
		React.FunctionComponent<
			React.SVGProps<SVGSVGElement> & {
				title?: string;
			}
		>
	> = {
		'Front Cut': FrontCutIcon,
		'Mid Cut': MidCutIcon,
		'Rear Cut': RearCutIcon,
		Underbody: UnderbodyIcon
	};
	const Icon = icons[cut.description] ?? OtherIcon;

	const leaves = useMemo(() => categoryLeaves(cut), [cut]);
	const count = useMemo(() => {
		const assemblies = uniqBy(
			leaves
				.flatMap(({ diagrams }) => diagrams)
				.flatMap(({ partSlots }) => partSlots)
				.flatMap(({ assemblies }) => assemblies),
			({ id }) => id
		);
		return sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)));
	}, [leaves, selection]);

	return (
		<button
			type="button"
			className={tlsx(
				'group rounded-md py-2 px-4 gap-2 hover:bg-gray-100 active:bg-gray-100 flex items-center justify-around flex-shrink-0',
				{
					'bg-gray-100': selected?.id === cut.id
				},
				className
			)}
			onClick={() => {
				actions.cut.set(cut);
			}}
			{...rest}
		>
			<Icon className="h-8 w-14" />
			<span className="font-medium">{cut.description}</span>

			{count > 0 && (
				<span
					className={tlsx(
						'ml-0.5 min-w-[2.75ch] h-[2.75ch] grid place-items-center rounded-full text-sm bg-gray-200 group-hover:bg-blue-600 group-active:bg-blue-600 group-hover:text-white group-active:text-white',
						{
							'bg-blue-600 text-white': selected?.id === cut.id
						}
					)}
				>
					{count}
				</span>
			)}
		</button>
	);
};

type PartsAllDiagramProps = InheritableElementProps<
	'button',
	{
		other: CategoryTreeLeaf;
		category?: CategoryTreeLeaf | null;
		selection: PartsFormData;
		actions: {
			category: {
				set: (cut: CategoryTreeLeaf) => void;
			};
		};
	}
>;

export const PartsAllDiagram = ({
	className,
	other,
	category,
	selection,
	actions,
	...rest
}: PartsAllDiagramProps) => {
	return (
		<button
			type="button"
			className={tlsx(
				'group rounded-md py-2 px-4 gap-2 hover:bg-gray-100 active:bg-gray-100 flex items-center justify-around flex-shrink-0',
				{
					'bg-gray-100': category?.id === other.id
				},
				className
			)}
			onClick={() => {
				actions.category.set(other);
			}}
			{...rest}
		>
			<AllIcon className="h-8 w-14" />
			<span className="font-medium">All diagrams</span>
		</button>
	);
};
