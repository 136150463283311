import { slice } from '.';
import { MESH_SCALE } from '../constants';
import {
	Diagram,
	DiagramHotspotMesh,
	DiagramLineMesh,
	DiagramMesh,
	DiagramPartSlotInfo,
	DiagramPolygonMesh,
	DiagramWhiteoutMesh
} from '../types';
import { polygonArea, polygonOverlap, relativeToCenter } from './geometry';

export const meshKindSortKey = (mesh: DiagramMesh) => {
	const key: Record<DiagramMesh['kind'], number> = {
		whiteout: 0,
		line: 1,
		hotspot: 2,
		polygon: 3
	};

	return key[mesh.kind] ?? 4;
};

export const meshAreaSortKey = (mesh: DiagramMesh) => {
	if (mesh.kind !== 'polygon') {
		return 0;
	}

	return -polygonArea(mesh.polygon);
};

export const relativeMesh = <Mesh extends DiagramMesh>(
	mesh: Mesh,
	sizes: { width: number; height: number; ratio: number; scale?: number }
): Mesh => {
	switch (mesh.kind) {
		case 'whiteout': {
			return {
				...mesh,
				rect: slice([
					relativeToCenter(mesh.rect[0], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					),
					relativeToCenter(mesh.rect[1], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					),
					relativeToCenter(mesh.rect[2], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					),
					relativeToCenter(mesh.rect[3], sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				])
			};
		}
		case 'line': {
			return {
				...mesh,
				from: mesh.from.map(vec2 =>
					relativeToCenter(vec2, sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				),
				to: mesh.to.map(vec2 =>
					relativeToCenter(vec2, sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				)
			};
		}
		case 'polygon': {
			return {
				...mesh,
				polygon: mesh.polygon.map(vec2 =>
					relativeToCenter(vec2, sizes.width, sizes.height).multiplyScalar(
						sizes.scale ?? MESH_SCALE
					)
				)
			};
		}
		case 'hotspot': {
			return {
				...mesh,
				point: relativeToCenter(mesh.point, sizes.width, sizes.height).multiplyScalar(
					sizes.scale ?? MESH_SCALE
				)
			};
		}
	}
};

const groupPolygonMeshes = (
	polygons: {
		mesh: DiagramPolygonMesh;
		partSlots: DiagramPartSlotInfo[];
	}[]
) => {
	const existings = [] as typeof polygons;
	for (const polygon of polygons) {
		let hasOverlap = false;
		for (const existing of existings) {
			const overlap = polygonOverlap(polygon.mesh.polygon, existing.mesh.polygon);

			if (overlap >= 0.9 && overlap <= 1) {
				existing.partSlots.push(...polygon.partSlots);
				hasOverlap = true;
			}
		}
		if (!hasOverlap) {
			existings.push(polygon);
		}
	}

	return existings;
};

export const layoutMeshes = (
	diagram: Diagram,
	sizes: { width: number; height: number; ratio: number; scale?: number }
) => {
	const meshes = {
		whiteouts: [] as { mesh: DiagramWhiteoutMesh; partSlot: DiagramPartSlotInfo }[],
		lines: [] as { mesh: DiagramLineMesh; partSlot: DiagramPartSlotInfo }[],
		polygons: [] as { mesh: DiagramPolygonMesh; partSlots: DiagramPartSlotInfo[] }[],
		hotspots: [] as { mesh: DiagramHotspotMesh; partSlot: DiagramPartSlotInfo }[]
	};
	for (const {
		meshes: { whiteouts, lines, polygons, hotspots },
		...partSlot
	} of diagram.partSlots) {
		meshes.whiteouts.push(
			...whiteouts.map(mesh => relativeMesh(mesh, sizes)).map(mesh => ({ mesh, partSlot }))
		);
		meshes.lines.push(
			...lines.map(mesh => relativeMesh(mesh, sizes)).map(mesh => ({ mesh, partSlot }))
		);
		meshes.polygons.push(
			...polygons
				.map(mesh => relativeMesh(mesh, sizes))
				.map(mesh => ({ mesh, partSlots: [partSlot] }))
		);
		meshes.hotspots.push(
			...hotspots.map(mesh => relativeMesh(mesh, sizes)).map(mesh => ({ mesh, partSlot }))
		);
	}

	return {
		meshes: [...meshes.whiteouts, ...meshes.lines, ...meshes.hotspots],
		polygons: groupPolygonMeshes(meshes.polygons)
	};
};
