import { Badge } from '@/app/atoms/badge';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { CheckIcon, ChevronRightIcon, TagIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Checkbox, CheckboxProps, Highlight } from '@mantine/core';
import { Fragment } from 'react';
import { useSearchValue } from '../../hooks/use-search-value';
import { DiagramAssembly } from '../../types';
import { tokenized } from '../../utils/search';
import { PartsHotpot } from '../parts-hotspot';

// const AVAILABILITY: Record<Availability, string> = {
// 	InStock: 'Available',
// 	OutOfStock: 'None available',
// 	NoSupply: 'No supply'
// };

// const CONFINDENCE_THRESHOLD = 10;

type PartsDisplayProps = InheritableElementProps<
	'div',
	{
		assembly: DiagramAssembly;
		checkbox?: Omit<CheckboxProps, 'id' | 'radius' | 'size'>;
		fitment?: boolean;
		onClick: () => void;
	}
>;

export const PartsDisplay = ({
	className,
	assembly,
	checkbox,
	fitment: tags,
	onClick,
	...rest
}: PartsDisplayProps) => {
	const q = useSearchValue();

	// todo (vincent) bring back later for when supply and recommeendation make sense
	// const [open, setOpen] = useState(true);
	// const recommended = useMemo(() => (assembly.confidence ?? 0) > CONFINDENCE_THRESHOLD, [assembly]);
	return (
		<div
			className={tlsx(
				'flex flex-col items-center gap-2 px-6 py-5 rounded-lg shadow-sm border bg-white',
				className
			)}
			{...rest}
		>
			<div className="flex items-center gap-4 w-full">
				<Checkbox radius="100%" size="lg" {...checkbox} />
				<PartsHotpot
					className="p-1 h-[3ch] min-w-[3ch] text-sm"
					code={assembly.code}
					highlighted
					onClick={onClick}
				/>
				<Highlight component="span" className="text-base" highlight={q ? [...tokenized(q)] : ''}>
					{assembly.description}
				</Highlight>
			</div>

			<div className="flex items-center flex-wrap gap-1 w-full mt-1 empty:hidden">
				{assembly.hcas.map((hca, index) => (
					<Fragment key={`${hca}-${index}`}>
						<span className="text-sm text-gray-600">{hca}</span>
						<ChevronRightIcon className="w-3 h-3 last:hidden" />
					</Fragment>
				))}
			</div>

			{/* <div className="flex items-center justify-between w-full mt-2">
				<ConfidenceBadge confidence={assembly.confidence ?? 0} />

				<button
					className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none mr-2"
					type="button"
					onClick={() => setOpen(prev => !prev)}
				>
					<ChevronDownIcon className={tlsx('w-4 h-4 transition-all', { 'rotate-180': open })} />
				</button>
			</div>

			<Collapse className="flex flex-col w-full" in={open}>
				<div className="flex items-center justify-between w-full">
					<dt className="sr-only">Part number</dt>
					<dd className="text-sm text-gray-600">{assembly.part.mpn}</dd>

					<dt className="sr-only">Grade</dt>
					<dd className="text-sm text-gray-600">
						{assembly.grades ? formatGradeRange(assembly.grades) : '--'}
					</dd>

					<dt className="sr-only">Availability</dt>
					<dd className="text-sm text-gray-600">
						{assembly.availability ? AVAILABILITY[assembly.availability] : '--'}
					</dd>
				</div>

			</Collapse> */}

			{tags && !!assembly.tags?.length && (
				<div className="flex items-center flex-wrap w-full mt-1 gap-2">
					{assembly.tags.map(({ kind, description }, index) => {
						const Icon = kind === 'yes' ? CheckIcon : kind === 'no' ? XMarkIcon : TagIcon;
						return (
							<Badge
								key={`${description}-${index}`}
								size="small"
								rounded
								variant={kind === 'yes' ? 'green' : kind === 'no' ? 'red' : 'white'}
							>
								<Badge.LeadingIcon as={Icon} />
								{description}
							</Badge>
						);
					})}
				</div>
			)}
		</div>
	);
};
