import { isString } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type PartialNull<T> = { [P in keyof T]?: T[P] | null | undefined };

// typescript magic
type SearchQueryOption<T extends string> = T | { name: T; separator: string };
type SearchQueryKey<O extends SearchQueryOption<string>> = O extends { name: string }
	? O['name']
	: O;
type SearchQueries<Options extends [...SearchQueryOption<string>[]]> = {
	[Option in Options[number] as `${SearchQueryKey<Option>}`]: Option extends { separator: string }
		? string[]
		: string;
};

/**
 * A nicer hook to use on top of useSearchParams to handle getting and updating multiple entries
 * - Handle both single string or array for values gracefully for each key
 * - Allow updating multiple keys at the same time
 *
 * @param options List of search param keys or option onto operate on
 *
 * @example
 * ```ts
 * const [{ q, highlighted }, setQueries] = useSearchQueries([
 * 	"q",
 * 	{ name: "highlighted", separator: "," }
 * ]);
 * // q - string | undefined
 * // highlighted - string[] | undefined
 * ```
 */
export const useSearchQueries = <
	Keys extends string,
	Options extends [...SearchQueryOption<Keys>[]] = [...SearchQueryOption<Keys>[]]
>(
	options: readonly [...Options]
) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const queries = useMemo(() => {
		const queries = {} as Record<string, string | string[]>;
		for (const opt of options) {
			if (isString(opt)) {
				const value = searchParams.get(opt);
				if (!value) {
					continue;
				}
				queries[opt] = value;
			} else {
				const values = searchParams.get(opt.name)?.split(opt.separator) ?? [];
				if (values.length === 0) {
					continue;
				}
				queries[opt.name] = values;
			}
		}
		return queries as Partial<SearchQueries<Options>>;
	}, [searchParams]);

	const setQueries = useCallback(
		(queries: PartialNull<SearchQueries<Options>>) =>
			setSearchParams(prev => {
				const newSearchParams = new URLSearchParams(prev);
				for (const opt of options) {
					const key = isString(opt) ? opt : opt.name;
					const value = queries[key as keyof SearchQueries<Options>];
					if (isString(value) && isString(opt)) {
						newSearchParams.set(opt, value);
					} else if (Array.isArray(value) && typeof opt === 'object' && 'separator' in opt) {
						newSearchParams.delete(key);
						if (value.length > 0) {
							newSearchParams.set(key, value.join(opt.separator));
						}
					} else if (value === null) {
						newSearchParams.delete(key);
					}
				}
				return newSearchParams;
			}),
		[setSearchParams]
	);

	return [queries, setQueries] as const;
};
