import { PartsCart } from '@/app/features/parts/components/parts-cart';
import { PartsCustomPart } from '@/app/features/parts/components/parts-custom-part';
import { PartsCuts } from '@/app/features/parts/components/parts-cuts';
import { PartsDiagram } from '@/app/features/parts/components/parts-diagram';
import { PartsSpotlight } from '@/app/features/parts/components/parts-spotlight';
import { useUpsertParts } from '@/app/features/parts/hooks/use-upsert-parts';
import { PartsFormData, PartsSelection } from '@/app/features/parts/types';
import { categoriesDiagrams, categoryLeaves } from '@/app/features/parts/utils';
import { createInitialPartsFormData } from '@/app/features/parts/utils/form';
import { filterCategoryNodes, filterOtherCategory } from '@/app/features/parts/utils/search';
import { withSignedIn } from '@/app/hoc/with-access';
import { useAnalytics } from '@/app/hooks/use-analytics';
import { useMeasurement } from '@/app/hooks/use-measure';
import { useSearchQueries } from '@/app/hooks/use-search-queries';
import { useUnsavedChanges } from '@/app/hooks/use-unsaved-changes';
import { equivalent } from '@/app/utils/common';
import { JobPartInput } from '@/sdk/lib';
import { isDefined } from '@/sdk/lib/utils/object';
import { jobsQueries } from '@/sdk/react';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { Button } from '@mantine/core';
import { RepairApp } from '@partly/analytics';
import { useSuspenseQueries } from '@tanstack/react-query';
import { compact, isNil, sortBy, sum, values } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { JobMainAction, JobSubActions } from '../job-detail';

type PageParams = {
	jobId: string;
};

const PartsPage = () => {
	const { jobId } = useParams<PageParams>();
	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const { value: nav } = useMeasurement('navigation-bar');
	const navigate = useNavigate();

	const [queries, setQueries] = useSearchQueries([
		'q',
		'diagram',
		'cut',
		'category',
		'cart',
		{ name: 'highlighted', separator: ',' }
	]);

	const [customPart, setCustomPart] = useState<string | null>(null);
	const [navigating, setNavigating] = useState(false);
	const [searching, setSearching] = useState(false);

	const { logEvent } = useAnalytics();
	const { mutateAsync: upsertParts } = useUpsertParts();
	const [{ data: assembliesData }, { data: partsData }] = useSuspenseQueries({
		queries: [jobsQueries.getPartAssembliesTree({ jobId }), jobsQueries.listParts({ jobId })]
	});

	const {
		categories: cuts,
		other,
		resources
	} = useMemo(() => categoriesDiagrams(assembliesData.tree.assemblies), [assembliesData]);

	const { control, handleSubmit, watch, formState, reset, setValue } = useForm<PartsFormData>({
		defaultValues: createInitialPartsFormData(partsData.parts, resources)
	});

	const selection = watch();
	const { isDirty, isSubmitting } = formState;

	const highlighted = useMemo(() => new Set(queries.highlighted ?? []), [queries.highlighted]);

	const filtered = useMemo(
		() => ({
			cuts: filterCategoryNodes(cuts, queries.q),
			other: filterOtherCategory(other, queries.q)
		}),
		[cuts, queries.q]
	);

	const cut = useMemo(() => {
		const cut = filtered.cuts.find(({ id }) => queries.cut === id);
		if (cut) {
			return cut;
		}
		if (filtered.other && queries.category && queries.category === filtered.other.id) {
			return null;
		}
		return filtered.cuts[0];
	}, [filtered, queries]);

	const leaves = useMemo(() => {
		const leaves = cut?.assemblies.flatMap(categoryLeaves) ?? [];
		if (filtered.other) {
			leaves.push(filtered.other);
		}
		return leaves;
	}, [cut, other]);

	const category = useMemo(
		() => leaves.find(({ id }) => id === queries.category),
		[leaves, queries]
	);

	const diagram = useMemo(
		() => category?.diagrams.find(({ id }) => id === queries.diagram),
		[category, queries]
	);

	const count = useMemo(
		() =>
			values(selection)
				.filter(isDefined)
				.filter(({ quantity }) => quantity > 0).length,
		[selection]
	);

	const gapcBrandId = useMemo(() => {
		return [...resources.assemblies.values()][0]?.part?.gapcBrandId;
	}, [resources]);

	const onSubmit = useCallback(
		async (data: PartsFormData) => {
			const selection = sortBy(compact(values(data)), ({ order }) => order ?? 0);
			if (isDirty) {
				const parts = values(selection).map(
					(selection): JobPartInput => ({
						gapcBrandId: selection.gapcBrandId,
						mpn: selection.mpn,
						quantity: selection.quantity,
						description: selection.description,
						partSlot: selection.partSlotIds?.gapcPartTypeId
							? {
									gapcPartTypeId: selection.partSlotIds.gapcPartTypeId,
									gapcPositionId: selection.partSlotIds.gapcPositionId
								}
							: null,
						assembly: selection.assemblyId
							? {
									id: selection.assemblyId,
									hcas: selection.hcas
								}
							: null
					})
				);

				await upsertParts({
					jobId,
					parts
				});

				logEvent(
					RepairApp.part_selection.parts_finalised({
						parts_count: parts.length
					})
				);

				// retain changes (job parts won't immediately get updated, should prevent added parts from flashing to default values)
				reset(undefined, { keepValues: true });
			}

			const hasAddedParts = sum(selection.map(selection => selection?.quantity ?? 0)) > 0;
			if (hasAddedParts) {
				navigate(`/job/${jobId}/supply`);
			}
		},
		[jobId, isDirty, upsertParts, reset, logEvent]
	);

	useUnsavedChanges(isDirty && !isSubmitting);

	return (
		<form
			id="parts-form"
			noValidate
			className="flex items-center place-items-center"
			onSubmit={handleSubmit(onSubmit)}
			data-testid="parts-section"
			style={{
				height: `calc(100dvh - ${nav?.height ?? 0}px)`,
				maxHeight: `calc(100dvh - ${nav?.height ?? 0}px)`
			}}
		>
			<JobMainAction>
				<PartsSpotlight
					cuts={cuts}
					other={other}
					open={searching}
					search={queries.q ?? ''}
					actions={{
						view: setSearching,
						filter: (q: string) => {
							if (!q) {
								setQueries({ q });
								return;
							}
							setQueries({
								q,
								category: null,
								diagram: null,
								highlighted: null
							});
						},
						jump: {
							category: (cut, category) => {
								setNavigating(false);
								setQueries({ cut, category, diagram: null, highlighted: null });
							},
							diagram: (cut, category, diagram) => {
								setNavigating(false);
								setQueries({ cut, category, diagram, highlighted: null });
							},
							part: (cut, category, diagram, partSlot) => {
								setNavigating(false);
								setQueries({ cut, category, diagram, highlighted: [partSlot] });
							}
						},
						custom: {
							add: description => setCustomPart(description)
						},
						add: assembly => {
							const data: PartsSelection = {
								mpn: assembly.part.mpn,
								gapcBrandId: assembly.part.gapcBrandId,
								partSlotIds: assembly.partSlotIds,
								quantity: 1,
								assemblyId: assembly.id,
								description: assembly.description,
								hcas: assembly.hcas,
								order: values(selection).filter(s => (s?.quantity ?? 0) > 0).length
							};

							setValue(assembly.id, data);
						}
					}}
				/>
			</JobMainAction>
			<JobSubActions>
				<Button
					key="parts-cart-button"
					className="w-fit ml-auto"
					type="button"
					onClick={() => setQueries({ cart: !queries.cart ? '1' : null })}
				>
					Cart
					<ShoppingBagIcon className="w-4 h-4 mx-1.5" />
					{count}
				</Button>
			</JobSubActions>
			<div
				className="relative w-full"
				style={{
					height: `calc(100dvh - ${nav?.height ?? 0}px)`,
					maxHeight: `calc(100dvh - ${nav?.height ?? 0}px)`
				}}
			>
				<PartsCuts
					cuts={filtered.cuts}
					other={filtered.other}
					cut={cut}
					selection={selection}
					category={category}
					actions={{
						cut: {
							set: ({ id }) => {
								if (id !== cut?.id) {
									setQueries({ cut: id, category: null, diagram: null });
								} else {
									setNavigating(true);
								}
							}
						},
						category: {
							set: category =>
								setQueries({ cut: null, category: category.id, diagram: null, highlighted: null })
						}
					}}
				/>

				<PartsDiagram
					className="flex-shrink-0"
					style={{
						height: `calc(100dvh - ${nav?.height ?? 0}px - 5rem)`,
						maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 5rem)`
					}}
					cut={cut}
					other={filtered.other}
					category={category}
					diagram={diagram}
					control={control}
					highlighted={highlighted}
					selection={selection}
					navigating={navigating}
					actions={{
						view: {
							open: () => setNavigating(true),
							close: () => setNavigating(false)
						},
						part: {
							highlight: ids => {
								if (equivalent(highlighted, new Set(ids))) {
									setQueries({ highlighted: null });
								} else {
									setQueries({ highlighted: ids });
								}
							}
						},
						category: {
							set: category =>
								setQueries({ category: category.id, diagram: null, highlighted: null })
						},
						diagram: {
							set: diagram => setQueries({ diagram: diagram.id, highlighted: null })
						},
						custom: {
							add: () => setCustomPart('')
						}
					}}
				/>
			</div>

			<PartsCart
				form="parts-form"
				className="flex-shrink-0"
				control={control}
				selection={selection}
				categories={leaves}
				diagram={diagram}
				highlighted={highlighted}
				open={!!queries.cart}
				changed={isDirty}
				actions={{
					close: () => setQueries({ cart: null }),
					part: {
						highlight: ids => {
							if (equivalent(highlighted, new Set(ids))) {
								setQueries({ highlighted: null });
							} else {
								setQueries({ highlighted: ids });
							}
						},
						jump: (category, diagram, partSlot) =>
							setQueries({ category, diagram, highlighted: [partSlot] })
					}
				}}
			/>

			<PartsCustomPart
				id="parts-custom-part"
				className="z-[90]"
				gapcBrandId={gapcBrandId}
				open={!isNil(customPart)}
				initialDescription={customPart ?? ''}
				resources={resources}
				onSubmit={({ mpn, description, id, assembly }) => {
					const data: PartsSelection = {
						gapcBrandId,
						mpn: mpn ?? '',
						order: count,
						partSlotIds: assembly?.partSlotIds ?? null,
						quantity: 1,
						assemblyId: assembly?.id ?? id,
						hcas: assembly?.hcas ?? [],
						description: assembly?.description ?? description
					};

					const selectionId = assembly?.id ?? id ?? v4();
					setValue(selectionId, data, { shouldDirty: true });
					setCustomPart(null);
				}}
				onClose={() => setCustomPart(null)}
			/>
		</form>
	);
};

export default withSignedIn(PartsPage);
