import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { CheckboxIcon } from '@mantine/core';

type PartsHotpotProps = InheritableElementProps<
	'button',
	{
		code: string | null;
		checked?: boolean;
		highlighted?: boolean;
		dimmed?: boolean;
		searched?: boolean;
		far?: boolean;
	}
>;

export const PartsHotpot = ({
	className,
	code,
	checked,
	dimmed,
	highlighted,
	searched,
	far,
	disabled,
	onClick,
	...rest
}: PartsHotpotProps) => {
	return (
		<button
			type="button"
			className={tlsx(
				'relative grid place-items-center p-0.5 h-[3ch] min-w-[3ch] text-sm text-center bg-gray-200 rounded select-none transition-all duration-150',
				{
					'opacity-20': dimmed
				},
				{
					'bg-blue-600 text-white': highlighted
				},
				{
					'opacity-40 cursor-not-allowed': disabled
				},
				{
					'min-w-0 w-1 h-1 rounded-full': !code
				},
				{
					'h-[3.5ch] min-w-[3.5ch] bg-emerald-600 text-white rounded-full': checked
				},
				{
					'h-[3.5ch] min-w-[3.5ch] bg-blue-600 text-white rounded-full': checked && highlighted
				},
				{
					'ring-2 ring-amber-600': searched
				},
				{
					'h-[0.4rem] min-w-[0.4rem] w-[0.4rem] rounded-full duration-75': far
				},
				className
			)}
			disabled={(!code && !checked) || disabled}
			onClick={onClick}
			{...rest}
		>
			{!far && (checked ? <CheckboxIcon indeterminate={false} className="w-3.5 h-3.5" /> : code)}
		</button>
	);
};
