// scaling up all mesh by a measure of 10 gives a full screen-ish size
export const MESH_SCALE = 10;

// default zoom / distance (in z axis), higher is lower zoom, lower is higher zoom
export const DEFAULT_DISTANCE_Z = 5;

// max / min scale for zooming in/out
export const MAX_MIN_DISTANCE_Z = 4.5;

// amount of distance increase/decrease each step
export const DISTANCE_Z_STEP = 0.25;

// minimum area threshold before a segment is considered "small"
export const SMALL_SEGMENT_AREA_THRESHOLD = 0.1;

// HCA Tree Top Layer
export const HCA_TOP_LAYERS = [
	'Front Cut',
	'Mid Cut',
	'Rear Cut',
	'Nose Cut',
	'Underbody'
] as const;
