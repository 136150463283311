import { Slice } from '@/app/features/parts/types';
import { slice } from '@/app/features/parts/utils';
import { compact, minBy, sortBy } from 'lodash-es';
import { Matrix3, Matrix4, Vector2, Vector3 } from 'three';

const EPSILON = 1e-10;

export const vec2 = (x: number, y: number) => new Vector2(x, y);
export const vec3 = (x: number, y: number, z: number) => new Vector3(x, y, z);

export const matrix3 = (
	m: Slice<Slice<number, 3>, 3> = [
		[1, 0, 0],
		[0, 1, 0],
		[0, 0, 1]
	]
) => new Matrix3(m[0][0], m[0][1], m[0][2], m[1][0], m[1][1], m[1][2], m[2][0], m[2][1], m[2][2]);

export const vec2to3 = (vec2: Vector2, z = 0) => vec3(vec2.x, vec2.y, z);

export const relativeToCenter = (vec: Vector2, width: number, height: number) => {
	const normalizedX = (vec.x - width / 2) / width;
	const normalizedY = -((vec.y - height / 2) / height) * (height / width);
	return vec2(normalizedX, normalizedY);
};

export const line2Area = (line2: Slice<Vector2, 2>) => {
	const x1 = Math.min(...line2.map(({ x }) => x));
	const x2 = Math.max(...line2.map(({ x }) => x));
	const y1 = Math.min(...line2.map(({ y }) => y));
	const y2 = Math.max(...line2.map(({ y }) => y));

	return Math.abs(x2 - x1) * Math.abs(y2 - y1);
};

export const polygonArea = (polygon: Vector2[]) => {
	const x1 = Math.min(...polygon.map(({ x }) => x));
	const x2 = Math.max(...polygon.map(({ x }) => x));
	const y1 = Math.min(...polygon.map(({ y }) => y));
	const y2 = Math.max(...polygon.map(({ y }) => y));

	return Math.abs(x2 - x1) * Math.abs(y2 - y1);
};

export const polygonCentroid = (polygon: Vector2[]) => {
	const centroid = vec2(0, 0);
	polygon.forEach(each => centroid.add(each));
	return centroid.divideScalar(polygon.length);
};

export const scalePolygon = (polygon: Vector2[], scale: number) => {
	const centroid = polygonCentroid(polygon);

	const translationToOrigin = matrix3().makeTranslation(-centroid.x, -centroid.y);
	const scaling = matrix3().makeScale(scale, scale);
	const translationBack = matrix3().makeTranslation(centroid.x, centroid.y);

	const transformMatrix = matrix3()
		.multiply(translationBack)
		.multiply(scaling)
		.multiply(translationToOrigin);

	return polygon.map(each => each.clone().applyMatrix3(transformMatrix));
};

export const scaleRect = (rect: Slice<Vector2, 4>, scale: number) => {
	const [topLeft, topRight, bottomLeft, bottomRight] = sortBy(
		rect,
		({ y }) => -y,
		({ x }) => x
	);

	const d = 1 - scale;

	return slice([
		vec2(topLeft.x - Math.abs(topLeft.x * d), topLeft.y + Math.abs(topLeft.y * d)),
		vec2(topRight.x + Math.abs(topRight.x * d), topRight.y + Math.abs(topRight.y * d)),
		vec2(bottomRight.x + Math.abs(bottomRight.x * d), bottomRight.y - Math.abs(bottomRight.y * d)),
		vec2(bottomLeft.x - Math.abs(bottomLeft.x * d), bottomLeft.y - Math.abs(bottomLeft.y * d))
	]);
};

export const move4Polygon = (polygon: Vector2[], matrix4: Matrix4) =>
	polygon
		.map(vec2to3)
		.map(each => each.clone().applyMatrix4(matrix4))
		.map(({ x, y }) => vec2(x, y));

export const line2Distance = ([from, to]: Slice<Vector2, 2>) => {
	const dx = Math.abs(from.x - to.x);
	const dy = Math.abs(from.y - to.y);

	// a^2 + b^2 = c^2
	return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
};

export const polygonOverlap = (lhs: Vector2[], rhs: Vector2[]) => {
	const l = polygonToLine2(lhs);
	const r = polygonToLine2(rhs);
	const la = polygonArea(lhs) * 100;
	const ra = polygonArea(rhs) * 100;

	if (Math.min(la, ra) / Math.max(la, ra) < 0.9) {
		return 0;
	}

	const x1 = Math.max(l[0].x, r[0].x);
	const x2 = Math.min(l[1].x, r[1].x);
	const y1 = Math.max(l[0].y, r[0].y);
	const y2 = Math.min(l[1].y, r[1].y);

	const overlapArea = Math.abs(x2 - x1) * Math.abs(y2 - y1) * 100;

	const lp = overlapArea / la;
	const rp = overlapArea / ra;
	return (lp + rp) / 2;
};

export const line2Intersection = ([p1, p2]: Slice<Vector2, 2>, [q1, q2]: Slice<Vector2, 2>) => {
	const s1 = vec2(p2.x - p1.x, p2.y - p1.y);
	const s2 = vec2(q2.x - q1.x, q2.y - q1.y);

	const det = -s2.x * s1.y + s1.x * s2.y;

	// parallel lines doesn't interect
	if (Math.abs(det) < EPSILON) {
		return undefined;
	}

	const s = (-s1.y * (p1.x - q1.x) + s1.x * (p1.y - q1.y)) / det;
	const t = (s2.x * (p1.y - q1.y) - s2.y * (p1.x - q1.x)) / det;

	if (s >= -EPSILON && s <= 1 + EPSILON && t >= -EPSILON && t <= 1 + EPSILON) {
		return vec2(p1.x + t * s1.x, p1.y + t * s1.y);
	}

	// no intersection for some reason
	return null;
};

export const polygonIntersection = ([from, to]: Slice<Vector2, 2>, polygon: Vector2[]) => {
	// doubled the line to force an intersection
	const dx = to.x - from.x;
	const dy = to.y - from.y;
	const doubled = slice([from, vec2(to.x + dx, to.y + dy)]);

	return minBy(
		compact(
			polygon.map((p1, i) => {
				const p2 = polygon[(i + 1) % polygon.length];
				return line2Intersection(doubled, [p1, p2]);
			})
		),
		intersection => line2Distance([from, intersection])
	);
};

export const line2ToRect = ([top, bottom]: Slice<Vector2, 2>) =>
	slice([top, vec2(bottom.x, top.y), bottom, vec2(top.x, bottom.y)]);

export const line2ToCenterPoint = ([top, bottom]: Slice<Vector2, 2>) =>
	vec2((top.x + bottom.x) / 2, (top.y + bottom.y) / 2);

export const polygonToLine2 = (polygon: Vector2[]) => {
	const top = vec2(Math.min(...polygon.map(({ x }) => x)), Math.min(...polygon.map(({ y }) => y)));
	const bottom = vec2(
		Math.max(...polygon.map(({ x }) => x)),
		Math.max(...polygon.map(({ y }) => y))
	);

	return slice([top, bottom]);
};
export const polygonToRect = (polygon: Vector2[]) => {
	return line2ToRect(polygonToLine2(polygon));
};

export const polygonToCenterPoint = (polygon: Vector2[]) => {
	const top = vec2(Math.min(...polygon.map(({ x }) => x)), Math.min(...polygon.map(({ y }) => y)));
	const bottom = vec2(
		Math.max(...polygon.map(({ x }) => x)),
		Math.max(...polygon.map(({ y }) => y))
	);

	return line2ToCenterPoint([top, bottom]);
};

export const polygonNexus = (lhs: Vector2[], rhs: Vector2[]) => {
	const lc = polygonToCenterPoint(lhs);
	const rc = polygonToCenterPoint(rhs);

	const li = polygonIntersection([rc, lc], lhs) ?? lc;
	const ri = polygonIntersection([lc, rc], rhs) ?? rc;

	return slice([li, ri]);
};
