import { Badge } from '@/app/atoms/badge';
import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import { useMeasurement } from '@/app/hooks/use-measure';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps, InheritableProps } from '@/types/utilties';
import { ReactComponent as AiLogo } from '@assets/parts/ai-logo.svg';
import {
	ArrowsPointingInIcon,
	ArrowUturnLeftIcon,
	ChevronRightIcon,
	Cog6ToothIcon,
	EllipsisVerticalIcon,
	EyeIcon,
	EyeSlashIcon,
	MagnifyingGlassIcon,
	MinusIcon,
	PlusIcon
} from '@heroicons/react/24/solid';
import { Divider, Highlight, Indicator, Loader, Menu, Popover, Switch } from '@mantine/core';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { isNil, sortBy, sum, uniqBy } from 'lodash-es';
import { ReactNode, Suspense, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Camera, MOUSE, TOUCH } from 'three';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { DEFAULT_DISTANCE_Z, MAX_MIN_DISTANCE_Z, MESH_SCALE } from '../../constants';
import { useImageSizes } from '../../hooks/use-image-dimensions';
import { useSearchValue } from '../../hooks/use-search-value';
import {
	CategoryTree,
	CategoryTreeLeaf,
	CategoryTreeNode,
	Diagram,
	PartsFormData
} from '../../types';
import { categoryLeaves } from '../../utils';
import { vec3 } from '../../utils/geometry';
import { layoutMeshes, meshAreaSortKey, meshKindSortKey } from '../../utils/mesh';
import { filterTokenMatch, tokenized } from '../../utils/search';
import {
	PartsDiagramBackground,
	PartsDiagramCodeLayer,
	PartsDiagramFallback,
	PartsDiagramHotspot,
	PartsDiagramNexusLine,
	PartsDiagramSegment
} from './mesh';

const optimalMeshScale = (ratio: number) => {
	const res = MESH_SCALE / (ratio * 1.5);
	return res;
};

type PartsDiagramRendererProps = InheritableProps<
	typeof Canvas,
	{
		diagram?: Diagram | null;
		hightlighted: Set<string>;
		selection: PartsFormData;
		zoom: number;
		hide?: boolean;
		actions: {
			part: {
				highlight: (partSlotIds: string[]) => void;
			};
			controls: {
				start: (control: OrbitControlsImpl) => void;
				change: (control: Camera) => void;
			};
		};
		children?: ReactNode;
	}
>;

export const PartsDiagramRenderer = ({
	className,
	diagram,
	hightlighted,
	selection,
	actions,
	hide,
	zoom,
	...rest
}: PartsDiagramRendererProps) => {
	const q = useSearchValue();
	const [errors, setErrors] = useState<Record<string, Error>>({});
	const { value: nav } = useMeasurement('navigation-bar');
	const imageSize = useImageSizes(diagram?.image?.full ?? null);

	const sizes = useMemo(() => {
		if (!imageSize || !nav) {
			return null;
		}
		return {
			width: imageSize.naturalWidth,
			height: imageSize.naturalHeight,
			ratio: imageSize.naturalHeight / imageSize.naturalWidth,
			scale: optimalMeshScale(imageSize.naturalHeight / imageSize.naturalWidth)
		};
	}, [imageSize, nav]);

	const segmented = useMemo(
		() => diagram?.partSlots?.some(({ meshes }) => meshes.polygons.length > 0) ?? false,
		[diagram]
	);

	const error = useMemo(() => (diagram ? errors?.[diagram.image.full] : null), [errors, diagram]);

	const { meshes, polygons } = useMemo(() => {
		if (!sizes || !diagram) {
			return { meshes: [], polygons: [] };
		}

		const { meshes, polygons } = layoutMeshes(diagram, sizes);

		return {
			meshes: sortBy(meshes, ({ mesh }) => meshKindSortKey(mesh)),
			polygons: sortBy(polygons, ({ mesh }) => meshAreaSortKey(mesh))
		};
	}, [diagram, sizes]);

	return (
		<>
			<Canvas
				className={tlsx('w-full h-full overflow-hidden', className)}
				style={{
					height: `calc(100dvh - ${nav?.height ?? 0}px - 5.5rem)`,
					maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 5.5rem)`
				}}
				{...rest}
			>
				{diagram && sizes && (
					<>
						<ErrorBoundary
							fallbackRender={() => (
								// todo (vincent) some images are not cors configured properly
								// this is decent fallback/render image as image (only caveat no hotspots)
								<PartsDiagramFallback
									key={diagram.id}
									url={diagram.image.full}
									ratio={sizes.ratio}
									scale={sizes.scale}
									filter={
										hide || !segmented ? 'opaque' : hightlighted.size > 0 ? 'dimmed' : 'background'
									}
								/>
							)}
							onError={err => setErrors(errors => ({ ...errors, [diagram.image.full]: err }))}
						>
							<PartsDiagramBackground
								key={diagram.id}
								url={diagram.image.full}
								ratio={sizes.ratio}
								scale={sizes.scale}
								filter={
									hide || !segmented ? 'opaque' : hightlighted.size > 0 ? 'dimmed' : 'background'
								}
							/>
						</ErrorBoundary>
						{!hide &&
							meshes.map(({ partSlot: { assemblies, code, id }, mesh }, index) => {
								const highlighted = hightlighted.has(id);
								const added = assemblies.some(({ id }) => (selection[id]?.quantity ?? 0) > 0);
								const searched = q
									? assemblies.some(({ searchables }) =>
											searchables.some(searchable => filterTokenMatch(searchable, q))
										)
									: false;
								const onClick = () => actions.part.highlight([id]);

								switch (mesh.kind) {
									case 'whiteout': {
										return (
											<PartsDiagramCodeLayer key={`${id}-whiteout-${index}`} rect={mesh.rect} />
										);
									}
									case 'line': {
										return (
											<PartsDiagramNexusLine
												key={`${id}-line-${index}`}
												from={mesh.from}
												to={mesh.to}
												highlighted={highlighted}
												checked={added}
											/>
										);
									}
									case 'hotspot': {
										return (
											<PartsDiagramHotspot
												key={`${id}-hotspot-${index}`}
												code={code}
												point={mesh.point}
												checked={added}
												highlighted={highlighted}
												searched={searched}
												far={zoom < 0.9 && segmented}
												onClick={onClick}
											/>
										);
									}
								}
							})}

						{!hide &&
							polygons.map(({ mesh, partSlots }, index) => {
								const ids = partSlots.map(({ id }) => id);
								const highlighted = ids.some(id => hightlighted.has(id));
								const added = partSlots.some(({ assemblies }) =>
									assemblies.some(({ id }) => (selection[id]?.quantity ?? 0) > 0)
								);

								const searched = q
									? partSlots.some(({ assemblies }) =>
											assemblies.some(({ searchables }) =>
												searchables.some(searchable => filterTokenMatch(searchable, q))
											)
										)
									: false;

								return (
									<ErrorBoundary key={`${ids.join('-')}-polygon-${index}`} fallback={null}>
										<PartsDiagramSegment
											url={diagram.image.full}
											ratio={sizes.ratio}
											scale={sizes.scale}
											polygon={mesh.polygon}
											position={vec3(0, 0, 0)}
											highlighted={highlighted}
											checked={added}
											searched={searched}
											renderOrder={meshes.length + index}
											onClick={() => actions.part.highlight(ids)}
										/>
									</ErrorBoundary>
								);
							})}
					</>
				)}

				<OrbitControls
					position={[0, 0, 0]}
					ref={orbitControls => {
						if (!orbitControls) {
							return;
						}
						actions.controls.start(orbitControls);
					}}
					enablePan
					enableDamping
					enableRotate={false}
					zoomSpeed={1.25}
					dampingFactor={0.1}
					maxDistance={DEFAULT_DISTANCE_Z + MAX_MIN_DISTANCE_Z}
					minDistance={DEFAULT_DISTANCE_Z - MAX_MIN_DISTANCE_Z}
					mouseButtons={{
						LEFT: MOUSE.PAN,
						MIDDLE: MOUSE.PAN
					}}
					touches={{
						ONE: TOUCH.PAN,
						TWO: TOUCH.DOLLY_PAN
					}}
					onChange={event => {
						if (!event) {
							return;
						}
						actions.controls.change(event.target.object);
					}}
				/>
			</Canvas>

			{segmented && (
				<div className="absolute bottom-6 right-6 z-[5] select-none">
					<Popover width={200} withinPortal position="top-end">
						<Popover.Target>
							<Badge className="cursor-pointer" size="small" rounded variant="purple">
								<Badge.LeadingIcon as={AiLogo} />
								Interactive diagram
							</Badge>
						</Popover.Target>
						<Popover.Dropdown className="flex flex-col gap-2">
							<span className="text-xs text-gray-600 [text-wrap:pretty]">
								Part in this diagram are interactive / clickable
							</span>
							<Divider className="my-1" />
							<div className="flex items-center gap-2.5 w-full">
								<span className="w-3 h-3 bg-purple-300 rounded border-2 border-purple-600" />
								<span className="text-xs">Interactive part</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="w-3 h-3 bg-blue-300 rounded border-2 border-blue-600" />
								<span className="text-xs">Highlighted part</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="w-3 h-3 bg-emerald-300 rounded border-2 border-emerald-600" />
								<span className="text-xs">Added part</span>
							</div>
							<div className="flex items-center gap-2.5 w-full">
								<span className="w-3 h-3 bg-amber-300 rounded border-2 border-amber-600" />
								<span className="text-xs">Part matching search</span>
							</div>
						</Popover.Dropdown>
					</Popover>
				</div>
			)}

			{!isNil(error) && (
				<div className="absolute bottom-6 right-6 z-[5]  select-none">
					<Popover width={200} withinPortal position="top-end">
						<Popover.Target>
							<Badge className="cursor-pointer" size="small" rounded variant="red">
								<Badge.LeadingIcon as={Cog6ToothIcon} />
								Fallback diagram
							</Badge>
						</Popover.Target>
						<Popover.Dropdown className="flex flex-col gap-2">
							<span className="text-xs text-gray-600 [text-wrap:pretty]">
								Encountered some issues this diagram, reverted to simple diagram
							</span>
						</Popover.Dropdown>
					</Popover>
				</div>
			)}
		</>
	);
};

export const PartsDiagramSuspenseRenderer = ({ className, ...rest }: PartsDiagramRendererProps) => (
	<Suspense
		fallback={
			<div key="parts-diagram-loader" className={tlsx('w-full grid place-items-center', className)}>
				<Loader variant="bars" />
			</div>
		}
	>
		<PartsDiagramRenderer className={className} {...rest} />
	</Suspense>
);

type PartsDiagramNavigationProps = InheritableElementProps<
	'div',
	{
		cut?: CategoryTreeNode | null;
		other?: CategoryTreeLeaf | null;
		selected?: CategoryTreeLeaf | null;
		diagram?: Diagram | null;
		selection: PartsFormData;
		open: boolean;
		actions: {
			view: {
				open: () => void;
				close: () => void;
			};
			category: {
				set: (category: CategoryTreeLeaf) => void;
			};
			diagram: { set: (diagram: Diagram) => void };
		};
	}
>;

export const PartsDiagramNavigation = ({
	className,
	cut,
	other,
	selected: selectedCategory,
	diagram: selectedDiagram,
	selection,
	open,
	actions,
	...rest
}: PartsDiagramNavigationProps) => {
	const { value: nav } = useMeasurement('navigation-bar');

	const categories = useMemo(() => cut?.assemblies ?? [], [cut]);

	const showingAll = useMemo(
		() => selectedCategory && other && selectedCategory.id === other.id,
		[selectedCategory, other]
	);

	useEffect(() => {
		if (!selectedCategory || !selectedDiagram) {
			actions.view.open();
		}
	}, [selectedCategory, selectedDiagram]);

	return (
		<>
			<div
				className={tlsx('absolute top-4 left-4 flex gap-4 z-20', {
					'inset-4 bottom-0': !selectedCategory || !selectedDiagram
				})}
			>
				{!showingAll && (
					<div
						className={tlsx(
							'h-fit flex flex-col rounded-lg shadow border bg-white p-2 flex-shrink-0 transition-all duration-300 overflow-auto text-base font-semibold',
							{ '!w-80 shadow-none': open },
							className
						)}
						style={{
							width: selectedCategory
								? `${Math.min(20, selectedCategory.description.length * 0.75 + 3)}rem`
								: '18rem',
							maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 2.5rem)`
						}}
						{...rest}
					>
						<button
							type="button"
							className="group flex items-center justify-between py-1.5 px-3 text-start"
							onClick={() => {
								if (!selectedCategory || !selectedDiagram) {
									return;
								}

								if (open) {
									actions.view.close();
								} else {
									actions.view.open();
								}
							}}
						>
							<h2 className="text-gray-900 text-base font-semibold">
								{!open && selectedCategory ? selectedCategory.description : cut?.description}
							</h2>
							<div className="flex items-center p-1 rounded text-sm group-hover:bg-gray-50 group-active:bg-gray-50 select-none">
								<ChevronRightIcon
									className={tlsx('w-4 h-4 transition-all', { 'rotate-180': open })}
								/>
							</div>
						</button>

						{open && (
							<div className="w-full p-2 empty:hidden">
								{categories.map(c => (
									<PartsDiagramCategoryTree
										key={c.id}
										category={c}
										selected={selectedCategory}
										selection={selection}
										onClick={actions.category.set}
									/>
								))}
							</div>
						)}
					</div>
				)}

				{selectedCategory && !selectedDiagram && (
					<div
						className="flex-1 h-max flex flex-wrap gap-3 overflow-auto"
						style={{
							maxHeight: `calc(100dvh - ${nav?.height ?? 0}px - 2rem)`
						}}
					>
						{selectedCategory.diagrams.map(diagram => (
							<PartsDiagramPreview
								key={diagram.id}
								diagram={diagram}
								selection={selection}
								onClick={() => {
									actions.view.close();
									actions.diagram.set(diagram);
								}}
							/>
						))}
					</div>
				)}
			</div>

			{!!selectedCategory?.diagrams?.length && !selectedDiagram && (
				<div className="absolute inset-0 z-[15] backdrop-blur-sm bg-[#FCFDFD]" />
			)}
		</>
	);
};

type PartsDiagramCategoryTreeProps = InheritableElementProps<
	'button',
	{
		category: CategoryTree;
		selected?: CategoryTreeLeaf | null;
		selection: PartsFormData;
		onClick: (category: CategoryTreeLeaf) => void;
	}
>;

export const PartsDiagramCategoryTree = ({
	className,
	category,
	selected,
	selection,
	onClick,
	...rest
}: PartsDiagramCategoryTreeProps) => {
	const q = useSearchValue();
	const [open, setOpen] = useState(false);
	const leaves = useMemo(() => categoryLeaves(category), [category]);
	const count = useMemo(() => {
		const assemblies = uniqBy(
			leaves
				.flatMap(({ diagrams }) => diagrams)
				.flatMap(({ partSlots }) => partSlots)
				.flatMap(({ assemblies }) => assemblies),
			({ id }) => id
		);
		return sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)));
	}, [leaves, selection]);

	const defaultOpen = useMemo(
		() => leaves.some(({ id }) => selected?.id === id),
		[leaves, selected]
	);

	useEffect(() => {
		if (defaultOpen) {
			setOpen(true);
		}
	}, [selected]);

	if (category.kind === 'leaf') {
		return (
			<button
				type="button"
				className={tlsx(
					'flex items-center text-base w-full font-medium text-start text-gray-800 ml-3 px-3 py-1 rounded-md hover:bg-gray-50 active:bg-gray-50 2 mt-3 first:mt-0',
					{
						'bg-blue-600 text-white hover:bg-blue-600 active:bg-blue-600':
							selected?.id === category.id
					},
					className
				)}
				onClick={() => onClick(category)}
			>
				<Highlight
					highlight={q ? [...tokenized(q)] : ''}
					highlightStyles={{
						'--tw-text-opacity': 1,
						color: 'rgb(0 0 0 / var(--tw-text-opacity))'
					}}
				>
					{category.description}
				</Highlight>
				{count > 0 && (
					<span
						className={tlsx('ml-3 text-sm leading-none font-medium text-blue-600 min-w-[2ch]', {
							'text-white': selected?.id === category.id
						})}
					>
						{count}
					</span>
				)}
			</button>
		);
	}
	return (
		<div className="mt-3 first:mt-0">
			<button
				type="button"
				className={tlsx(
					'group flex w-full items-center gap-2 text-start hover:bg-gray-50 active:bg-gray-50 py-1 px-2',
					className
				)}
				onClick={() => setOpen(prev => !prev)}
				{...rest}
			>
				<ChevronRightIcon className="w-3 h-3 group-data-[open]:rotate-90" />
				<Highlight
					component="span"
					className="text-base text-start font-medium text-gray-800"
					highlight={q ? [...tokenized(q)] : ''}
				>
					{category.description}
				</Highlight>
				{count > 0 && (
					<span className="ml-1 text-sm leading-none font-medium text-blue-600 min-w-[2ch]">
						{count}
					</span>
				)}
			</button>
			<div className={tlsx('mt-3 pl-4', { hidden: !open })}>
				{category.assemblies.map(sub => (
					<PartsDiagramCategoryTree
						key={sub.id}
						category={sub}
						selected={selected}
						selection={selection}
						onClick={onClick}
					/>
				))}
			</div>
		</div>
	);
};

type PartsDiagramPreviewProps = InheritableElementProps<
	'button',
	{
		diagram: Diagram;
		selection: PartsFormData;
		onClick: () => void;
	}
>;

export const PartsDiagramPreview = ({
	className,
	diagram,
	selection,
	onClick,
	...rest
}: PartsDiagramPreviewProps) => {
	const q = useSearchValue();
	const count = useMemo(() => {
		const assemblies = uniqBy(
			diagram.partSlots.flatMap(({ assemblies }) => assemblies),
			({ id }) => id
		);
		return sum(assemblies.map(({ id }) => ((selection[id]?.quantity ?? 0) > 0 ? 1 : 0)));
	}, [diagram, selection]);
	return (
		<Indicator
			className="text-sm"
			zIndex={90}
			disabled={count === 0}
			offset={16}
			size="1.5rem"
			label={`${count}`}
		>
			<button
				type="button"
				className={tlsx(
					'flex flex-col items-center justify-between gap-2 py-3 px-5 bg-white border rounded-lg w-56 h-64 xl:w-60 xl:h-72',
					className
				)}
				onClick={onClick}
				{...rest}
			>
				<div className="flex-1 grid place-items-center/">
					<ImageWithSkeleton
						className="w-48 h-48 xl:w-52 xl:h-52 object-scale-down"
						src={diagram.image.thumb}
						loading="lazy"
					/>
				</div>
				<Highlight
					component="span"
					className="text-xs font-medium text-gray-900"
					highlight={q ? [...tokenized(q)] : ''}
				>
					{`${diagram.code}: ${diagram.description}`}
				</Highlight>
			</button>
		</Indicator>
	);
};

type PartsDiagramToolbarProps = InheritableElementProps<
	'div',
	{
		category: CategoryTreeLeaf;
		diagram: Diagram;
		zoom: number;
		actions: {
			zoom: {
				in: () => void;
				out: () => void;
			};
			hide: (hide: boolean) => void;
			menu: {
				custom: {
					add: () => void;
				};
				diagram: {
					recenter: () => void;
					resetZoom: () => void;
					resetAll: () => void;
				};
			};
		};
	}
>;

export const PartsDiagramToolbar = ({
	className,
	category,
	diagram,
	zoom,
	actions,
	...rest
}: PartsDiagramToolbarProps) => {
	return (
		<div
			className={tlsx(
				'absolute bottom-6 flex items-center min-w-[10rem] rounded-lg shadow-md border px-1 bg-white z-10',
				className
			)}
			{...rest}
		>
			<div className="flex items-center py-1 px-1 gap-1.5">
				<button
					type="button"
					className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none"
					onClick={actions.zoom.in}
				>
					<PlusIcon className="w-4 h-4" />
				</button>
				<span className="text-sm min-w-[4ch] text-end select-none">{Math.round(zoom * 100)}%</span>
				<button
					type="button"
					className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none"
					onClick={actions.zoom.out}
				>
					<MinusIcon className="w-4 h-4" />
				</button>
			</div>
			<Switch
				className="mx-3"
				onChange={e => actions.hide(e.target.checked)}
				onLabel={<EyeSlashIcon className="w-4 h-4" />}
				offLabel={<EyeIcon className="w-4 h-4" />}
			/>
			<Menu shadow="md" width={200}>
				<Menu.Target>
					<button
						type="button"
						className="flex items-center p-1 rounded text-sm hover:bg-gray-50 active:bg-gray-50 select-none mr-2"
					>
						<EllipsisVerticalIcon className="w-4 h-4" />
					</button>
				</Menu.Target>

				<Menu.Dropdown>
					<Menu.Label>Manual part</Menu.Label>
					<Menu.Item
						type="button"
						icon={<PlusIcon className="w-4 h-4" />}
						onClick={actions.menu.custom.add}
					>
						Add part
					</Menu.Item>
					<Menu.Divider />
					<Menu.Label>Diagram</Menu.Label>
					<Menu.Item
						type="button"
						icon={<ArrowsPointingInIcon className="w-4 h-4" />}
						onClick={actions.menu.diagram.recenter}
					>
						Re-center
					</Menu.Item>
					<Menu.Item
						type="button"
						icon={<MagnifyingGlassIcon className="w-4 h-4" />}
						onClick={actions.menu.diagram.resetZoom}
					>
						Reset Zoom
					</Menu.Item>
					<Menu.Item
						type="button"
						color="red"
						className="group"
						icon={<ArrowUturnLeftIcon className="w-4 h-4" />}
						onClick={actions.menu.diagram.resetAll}
					>
						Reset View
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		</div>
	);
};
