import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import { tlsx } from '@/app/utils/tw-merge';
import { order } from '@/sdk/reflect/reflect';
import { InheritableElementProps } from '@/types/utilties';

type OrderRequestImageProps = InheritableElementProps<'div', { images: order.OrderImage[] }>;

export const OrderReuqestImages = ({ images, className, ...rest }: OrderRequestImageProps) => {
	if (images.length === 0) {
		return null;
	}

	return (
		<div className={tlsx('flex items-center gap-3 overflow-x-auto', className)} {...rest}>
			{images.map(image => (
				// todo: image with skeleton doesn't add classname to outer
				<div className="flex-shrink-0" key={image.original}>
					<ImageWithSkeleton src={image.original} className="w-16 h-16 rounded object-cover" />
				</div>
			))}
		</div>
	);
};
