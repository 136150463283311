import { EmptyState } from '@/app/atoms/empty-state';
import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import StandardDialog, { StandardDialogProps } from '@/app/organisms/standard-dialog';
import { tlsx } from '@/app/utils/tw-merge';
import { Checkbox } from '@headlessui/react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { Button } from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { useOrderImageSync } from '../../hooks/use-order-image-sync';
import { OrderRequestModel } from '../../models';

// We need to remove the dots to avoid issues with the form
// splitting things
const removeDots = (str: string) => str.replace(/\./g, '');

export type OrderImageFormSelection = Record<string, { url: string; checked: boolean }>;
type OrderJobImagePickerModalProps = {
	model: OrderRequestModel;
	onSubmit: (images: string[]) => void;
} & StandardDialogProps;

export const OrderJobImagePickerModal = ({
	model,
	open,
	onClose,
	onSubmit
}: OrderJobImagePickerModalProps) => {
	const { images, canSync, isSyncing, sync } = useOrderImageSync(model);

	const form = useForm<OrderImageFormSelection>({
		defaultValues: model.images.reduce((acc, image) => {
			const sanitisedKey = removeDots(image.original);
			acc[sanitisedKey] = { url: image.original, checked: true };
			return acc;
		}, {} as OrderImageFormSelection)
	});

	const handleOnSubmit = (data: OrderImageFormSelection) => {
		const selectedImages = Object.entries(data)
			.filter(([_, item]) => item?.checked)
			.map(([_, { url }]) => url);

		onSubmit(selectedImages);
		// reset default values
		form.reset(data);
	};

	return (
		<StandardDialog open={open} onClose={onClose}>
			<StandardDialog.Title>Attach photos</StandardDialog.Title>
			<StandardDialog.Content className="w-[484px]">
				<form noValidate onSubmit={form.handleSubmit(handleOnSubmit)}>
					{images.length === 0 ? (
						<EmptyState>
							<EmptyState.Title>No images available</EmptyState.Title>
							<EmptyState.Description>
								Try syncing from iBodyShop if available
							</EmptyState.Description>
						</EmptyState>
					) : (
						<div className="p-1 overflow-y-auto max-h-[500px]">
							<div className="grid grid-cols-4 gap-3">
								{images.map(image => (
									<Controller
										key={image}
										name={removeDots(image)}
										control={form.control}
										render={({ field: { value, onChange, ...rest } }) => (
											<Checkbox
												checked={value?.checked ?? false}
												onChange={checked => onChange({ url: image, checked })}
												className="cursor-pointer"
												{...rest}
											>
												<ImageWithSkeleton
													className={tlsx('aspect-square rounded object-cover', {
														'ring-2 ring-theme-600 ring-offset-2': value?.checked
													})}
													src={image}
													alt=""
												/>
											</Checkbox>
										)}
									/>
								))}
							</div>
						</div>
					)}
					<div className="mt-6 flex items-center justify-between">
						<Button
							size="sm"
							variant="default"
							onClick={sync}
							loading={isSyncing}
							disabled={!canSync}
							leftIcon={<ArrowPathIcon className="w-4 h-4" />}
						>
							Sync from iBodyShop
						</Button>
						<Button disabled={images.length === 0} type="submit">
							Save
						</Button>
					</div>
				</form>
			</StandardDialog.Content>
		</StandardDialog>
	);
};
